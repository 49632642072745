import type { ShopOffer } from '@patrianna/shared-patrianna-types/store/ShopModule'
import type { GetOffersResponse } from '@patrianna/shared-patrianna-types/websocket/response'
import { nextTick } from '@patrianna/shared-utils'
import { getQuickPurchaseSupportedTypeSelector } from '@patrianna-payments/shared-store/payments/store/selectors'

import { trackEvent } from 'config/analytic'
import type { TypedThunk } from 'store'
import { sweepstakeEnabledSelector } from 'store/modules/appConfig/selectors'
import { getActiveModalCurrencyIdSelector } from 'store/modules/currencies/selectors'
import { openDialog, replaceDialog } from 'store/modules/dialog/actions'
import { getDialogVisibilityByNameSelector } from 'store/modules/dialog/selectors'
import { getGcGeneratorEnabled } from 'store/modules/gcGenerator/selectors'
import { getModalOffersSelector } from 'store/modules/shop/selectors'
import type { OpenShopLocation } from 'store/modules/shop/types'
import { getActiveGameCodeSelector, isLiveCasinoGameLaunchedSelector } from 'store/modules/slotGameFlow/selectors'
import { getUserXpLevelSelector, shouldForceVerifyKYCSelector } from 'store/modules/user/selectors'
import ROUTES from 'temp/routes.json'

import { waitingForSessionDialogsData } from '../sessionDialogs/actions'
import { openSnackbar } from '../snackbar/actions'

import { actions } from './slice'

export const {
  clearProducts,
  setOffersAfterPurchase,
  addLastPurchasedOffer,
  addAppliedOffers,
  addOffersAction,
  setPurchaseLimits,
  setActivatedGame,
} = actions

const OFFERS_STORAGE_KEY = 'offers'
const offerFilterCondition = (offer: ShopOffer): boolean => offer.offerType === 'one_time'

export const getOffers =
  (shouldFindDifference: boolean = false, cb?: () => void): TypedThunk =>
  (dispatch, getState, { gateway }) => {
    return gateway
      .emit<GetOffersResponse>({ type: 'GetOffersRequest' })
      .then((body) => {
        // get old offers from session storage
        const oldOffersString = sessionStorage.getItem(OFFERS_STORAGE_KEY)
        let offersAfterPurchase: ShopOffer[] = []
        if (oldOffersString && shouldFindDifference) {
          const oldOffersCodes = new Set<string>(JSON.parse(oldOffersString))
          // find difference between old and new offers and filter it
          offersAfterPurchase = body.offers.filter(
            (offer) => !oldOffersCodes.has(offer.code) && offerFilterCondition(offer)
          )
        }

        if (shouldFindDifference) {
          // get all offer codes with by filter condition
          const offerCodes = body.offers.reduce((acc: string[], offer) => {
            if (offerFilterCondition(offer)) {
              acc.push(offer.code)
            }

            return acc
          }, [])

          // save offerCodes to session storage
          sessionStorage.setItem(OFFERS_STORAGE_KEY, JSON.stringify(offerCodes))
        }

        dispatch(addOffersAction({ offers: body.offers }))
        dispatch(addAppliedOffers({ appliedOffers: body.applied }))
        dispatch(addLastPurchasedOffer({ lastPurchasedOffer: body.lastPurchasedOffer }))
        dispatch(waitingForSessionDialogsData({ offers: true }))
        dispatch(setOffersAfterPurchase({ offersAfterPurchase }))

        cb?.()
        if (body.limitAmount) {
          dispatch(
            setPurchaseLimits({
              limits: {
                limitAmount: body.limitAmount,
                limitAvailable: body.limitAvailable,
                limitEnd: body.limitEnd,
                limitPeriod: body.limitPeriod,
              },
            })
          )
        }
      })
      .catch((error) => {
        if (error?.status?.errorText && error?.errorCode !== 'err_timeout') {
          dispatch(
            openSnackbar({
              message: error.status.errorText,
            })
          )
        }
      })
  }

export const openExtraSweepDialogIfPossible = (): TypedThunk => (dispatch, getState) => {
  const blockedByKYC = shouldForceVerifyKYCSelector(getState())
  const bonusOffer = getModalOffersSelector(getState())
  const isSweepAvailable = sweepstakeEnabledSelector(getState())
  const isPaymentDialogVisible = getDialogVisibilityByNameSelector(getState(), 'PAYMENT_DIALOG')

  if (bonusOffer?.length && isSweepAvailable && !isPaymentDialogVisible && !blockedByKYC) {
    dispatch(replaceDialog({ modalName: 'EXTRA_GOLD_DIALOG' }))
  }
}

export const outOfMoneyHandler =
  (name: string, gameType: 'slots' | 'lottery'): TypedThunk =>
  (dispatch, getState) => {
    switch (gameType) {
      case 'slots': {
        const userLevel = getUserXpLevelSelector(getState())

        trackEvent('spin_out_of_balance', { category: name, label: userLevel.level.toString() })
        break
      }
      case 'lottery': {
        const activeCurrency = getActiveModalCurrencyIdSelector(getState())
        const userLevel = getUserXpLevelSelector(getState())

        trackEvent(`lotto_insufficient_funds_${activeCurrency}`.toLowerCase(), {
          category: name,
          label: userLevel.level.toString(),
        })
        break
      }
      default: {
        break
      }
    }
  }

export const openShopHandler =
  ({
    isMobile,
    cta = 'get_coins',
    location = 'other',
    routerPush,
    isQuickPurchaseFlow,
    trackQuickPurchaseEvent = false,
  }: {
    isMobile: boolean
    routerPush: (path: string, callback?: () => void) => void
    cta?: 'gift_icon' | 'get_coins' | 'get_package'
    location?: OpenShopLocation
    isQuickPurchaseFlow?: boolean
    trackQuickPurchaseEvent?: boolean
  }): TypedThunk =>
  (dispatch, getState) => {
    const sweepstakeEnabled = sweepstakeEnabledSelector(getState())
    const activeGame = getActiveGameCodeSelector(getState())
    const isGCGeneratorEnabled = getGcGeneratorEnabled(getState())
    const locationPathname = window.location.pathname
    const liveCasinoGamePlayPage = isLiveCasinoGameLaunchedSelector(getState())
    const shouldDisableShop = shouldForceVerifyKYCSelector(getState())

    const { isQuickPurchase, isQuickPurchaseGetCoins } = getQuickPurchaseSupportedTypeSelector(
      getState(),
      locationPathname
    )

    if (isGCGeneratorEnabled) {
      return
    }

    if ((isQuickPurchase || isQuickPurchaseGetCoins) && isQuickPurchaseFlow) {
      if (trackQuickPurchaseEvent) {
        void trackEvent('QP_Header')
      }

      return dispatch(openDialog({ modalName: 'QUICK_PURCHASE_DIALOG' }))
    }

    trackEvent('clicked_on_shop_button', {
      category: cta,
      label: location,
      mode: sweepstakeEnabled ? 'SC' : 'GC',
      page_path: locationPathname,
    })
    // open offer dialog after shop was opened

    if (activeGame) {
      // if the user pays via mazooma
      // the store will be reset and 'isActivatedGame' will be 'false' even if the user has opened game
      // to avoid any unexpected behavior we set this value to the session store
      nextTick(() => {
        const gameRoute = `${locationPathname}${window.location.search}`
        sessionStorage.setItem('activeGameRoute', JSON.stringify(gameRoute))
        dispatch(setActivatedGame({ activeGameRoute: gameRoute }))
      })
    } else {
      nextTick(() => {
        dispatch(setActivatedGame({ activeGameRoute: '' }))
      })
    }

    if (isMobile) {
      if (!shouldDisableShop) {
        nextTick(() => {
          dispatch(openDialog({ modalName: 'SHOP_DIALOG' }))
        })
      }
    } else if (liveCasinoGamePlayPage) {
      window.open(ROUTES.SHOP, '_blank')
    } else {
      routerPush(ROUTES.SHOP)
    }
  }

export const setDefaultActivatedGame = (): TypedThunk => (dispatch) => {
  if (sessionStorage.getItem('activeGameRoute')) {
    dispatch(setActivatedGame({ activeGameRoute: JSON.parse(window.sessionStorage.getItem('activeGameRoute')) }))
    sessionStorage.removeItem('activeGameRoute')
  }
}

// const IS_OFFER_PROCESSING = false
