import type { AppliedShopOffer, PurchaseLimits, ShopOffer } from '@patrianna/shared-patrianna-types/store/ShopModule'
import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'

import type { ShopState } from 'store/types/ShopModule'

const initialState: ShopState = {
  offers: [],
  applied: [],
  lastPurchasedOffer: null,
  limits: null,
  offersAfterPurchase: [],
  activeGameRoute: '',
}

export const shopSlice = createSlice({
  name: 'shop',
  initialState,
  reducers: {
    setActivatedGame: (state, action: PayloadAction<{ activeGameRoute: string }>) => {
      state.activeGameRoute = action.payload.activeGameRoute
    },
    addOffersAction: (state, action: PayloadAction<{ offers: Array<ShopOffer> }>) => {
      state.offers = action.payload.offers
    },

    addAppliedOffers: (state, action: PayloadAction<{ appliedOffers: Array<AppliedShopOffer> }>) => {
      state.applied = action.payload.appliedOffers
    },

    addLastPurchasedOffer: (state, action: PayloadAction<{ lastPurchasedOffer: ShopOffer }>) => {
      state.lastPurchasedOffer = action.payload.lastPurchasedOffer
    },

    setPurchaseLimits: (state, action: PayloadAction<{ limits: PurchaseLimits | null }>) => {
      state.limits = action.payload.limits
    },

    setOffersAfterPurchase: (state, action: PayloadAction<{ offersAfterPurchase: Array<ShopOffer> }>) => {
      state.offersAfterPurchase = action.payload.offersAfterPurchase
    },

    clearProducts: () => initialState,
  },
})

export type ActionsTypes = ReturnType<InferValueTypes<typeof shopSlice.actions>>
export const { actions } = shopSlice
