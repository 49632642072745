'use client'

import { nextTick } from '@patrianna/shared-utils'
import type { LinkProps } from 'next/link'
import NextLink from 'next/link'
import { useParams, usePathname } from 'next/navigation'
import NProgress from 'nprogress'
import type { ReactNode, MouseEvent } from 'react'

import { getLanguageHref } from 'app/utils/getLanguageHref'
import ROUTES from 'temp/routes.json'
import { handleDelayedExternalRedirect } from 'utils/handleDelayedExternalRedirect'

export type Props = {
  href: string
  children?: ReactNode
  className?: string
  target?: '_blank' | ''
  rel?: string
  title?: string
  language?: string
  // HACK for disabling yieldToMainCallback for payment dialog
  isDisabledYieldToMainCallback?: boolean
  role?: string
} & LinkProps

export function Link(props: Props) {
  const { children, href, passHref, language, onClick, isDisabledYieldToMainCallback, ...rest } = props
  const pathname = usePathname()
  const { locale } = useParams()
  const externalHref = href?.startsWith('http')
  const languageHref = getLanguageHref({ href, language, locale })

  if (externalHref) {
    // The default behavior for external links must be overridden with a
    // delayed redirect to process the next browser render before the navigation.
    // It results in a lower INP value
    const onExternalLinkClick = (e: MouseEvent<HTMLAnchorElement>) => {
      e.preventDefault()
      handleDelayedExternalRedirect(href, props.target)
    }

    return (
      <a href={href} onClick={onExternalLinkClick} {...rest}>
        {children}
      </a>
    )
  }

  function handleClick(event: MouseEvent<HTMLAnchorElement>) {
    const nextHref = languageHref === '/' ? ROUTES.INDEX : languageHref

    if (pathname !== nextHref) {
      NProgress.configure({ showSpinner: false }).start()
    }

    if (onClick) {
      if (isDisabledYieldToMainCallback) {
        onClick(event)
      } else {
        nextTick(() => {
          onClick(event)
        })
      }
    }
  }

  return (
    <NextLink href={languageHref} passHref={passHref} prefetch={false} onClick={handleClick} {...rest}>
      {children}
    </NextLink>
  )
}
