import type { DailyBonusInfo } from '@patrianna/shared-patrianna-types/store/AccountBonusesModule'

export const isTodayUTC = (year: number, month: number, day: number) => {
  const now = new Date()

  return year === now.getUTCFullYear() && month === now.getUTCMonth() && day === now.getUTCDate()
}

export const isFutureUTC = (year: number, month: number, day: number) => {
  const now = new Date()

  return year > now.getUTCFullYear() || month > now.getUTCMonth() || day > now.getUTCDate()
}

export const getActiveBonuses = (dailyBonuses: DailyBonusInfo[]) => {
  return dailyBonuses.sort((a, b) => a.offset - b.offset)
}

export const getTodayBonus = (dailyBonuses: DailyBonusInfo[] | null): DailyBonusInfo | undefined => {
  return dailyBonuses?.find((i) => {
    return i?.eligible
  })
}
